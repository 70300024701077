@import "./../theme/mixins";

.c-notification-area {
  @include window-box--small();
  position: fixed;
  max-width: 50%;
  z-index: $z-over-everything + 100;
  right: 0;
  bottom: 0;
}
