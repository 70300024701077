@import "~blaze/scss/components.headings";
@import "~blaze/scss/components.typography";
@import "~blaze/scss/components.lists";
@import "~blaze/scss/components.links";
@import "~blaze/scss/components.navs";
@import "~blaze/scss/components.buttons";
@import "~blaze/scss/components.inputs";
@import "~blaze/scss/components.input-groups";
@import "~blaze/scss/components.hints";
@import "~blaze/scss/components.progress";
@import "~blaze/scss/components.cards";
@import "~blaze/scss/components.tables";
@import "~blaze/scss/components.alerts";
@import "~blaze/scss/components.overlays";

body {
  @extend .c-text;
}

// Links
a {
  @extend .c-link;
}

// Inputs
.c-field {
  color: $field-color;
  box-sizing: border-box;
}

// Tables
.c-table {
  &__row {
    &--v-align-top {
      align-items: flex-start;
    }
    &--v-align-center {
      align-items: center;
    }
    &--v-align-baseline {
      align-items: baseline;
    }
    &--v-align-bottom {
      align-items: flex-end;
    }
    &--active {
      background-color: $color-bg-brand;
    }
  }
  &__cell {
    &--width-fixed {
      flex: initial;
    }
    &--h-align-left {
      justify-content: flex-start;
    }
    &--h-align-right {
      justify-content: flex-end;
    }
    &--h-align-center {
      justify-content: center;
    }
  }
}

.c-plain-table {

  width: $table-width;
  margin: $table-margin;
  padding: $table-padding;
  border: $table-border;
  border-collapse: collapse;
  border-spacing: 0;

  &__body {
    &--striped {
      > .c-plain-table__row:nth-of-type(odd) > .c-plain-table__cell {
        background-color: $table-row-striped-background-color;
        color: $table-row-striped-color;
      }
    }
  }

  &__row {
    &--heading {
      border-bottom: $table-heading-border;
      background-color: $table-heading-background-color;
      color: $table-heading-color;
      font-size: $text-font-size-medium;
      font-weight: $table-heading-font-weight;
    }
    &--text-center {
      text-align: center;
    }
    &--text-right {
      text-align: right;
    }
    &--text-left {
      text-align: left;
    }
  }

  &__cell, &__head-cell {
    padding: $table-cell-padding;
    &--text-center {
      text-align: center;
    }
    &--text-right {
      text-align: right;
    }
    &--text-left {
      text-align: left;
    }
  }
}

@keyframes progress-bar-stripes {
  from { background-position: $progress-striped-dash-size 0; }
  to { background-position: 0 0; }
}

.c-progress {
  // HACK: It's not possible to overwrite SCSS variable for border-radius
  // Specify the value directly here. Rounded progress is possible
  // with --rounded modifier
  border-radius: 0;
  &--rounded {
    border-radius: $progress-border-radius;
  }

  &__bar {
    &--striped {
      @include gradient-striped($color-black);
      background-size: $progress-striped-dash-size $progress-striped-dash-size;
    }
    &--animated {
      animation: progress-bar-stripes 1s linear infinite;
    }
  }
}

// Widgets
.c-widget {
  display: flex;
  flex-direction: column;
  background-color: $card-background-color;
  &__header {
    @include window-box--small();
    background-color: $color-info;
  }
  &__body {
    @include window-box--small();
    flex: 1;
  }
}

// Spinner (from SpinKit)
.c-spinner-chasing-dots {
  @keyframes sk-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
    }
    50% {
      transform: scale(1.0);
    }
  }

  position:relative;
  text-align: center;
  animation: sk-rotate 2.0s infinite linear;

  &__dot1,&__dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: $color-brand;
    border-radius: 100%;

    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  &__dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1.0s;
  }
}

// Property Table
.c-property-table {
  border-collapse: separate;
  border-spacing: 0;

  @for $i from 1 through length($size-names) {
    &--spacing-#{nth($size-names, $i)} {
      border-spacing: 0 nth($sizes, $i);
    }
  }

  > tbody > tr {
    > td {
      margin: 0;
      vertical-align: middle;
    }
  }

  &__name {
    padding: 0 $spacing-small 0 0;
    white-space: nowrap;
  }

  &__value {
    padding: 0;
    width: 100%;
    text-align: right;
  }
}

// Icon Button
.c-icon-button {
  display: inline;
  max-width: 100%;
  margin: $button-margin;
  padding: 0;
  outline: 0;
  font-family: $button-font-family;
  font-size: $button-font-size;
  line-height: $button-line-height;
  text-align: $button-text-align;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: $button-white-space;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  appearance: none;
  user-select: none;
  border: none;
  background: none;

  &:disabled {
    cursor: not-allowed;
    opacity: $button-disabled-opacity;
  }

  &__content {
    display: inline-flex;
    align-items: center;
  }

  @each $size in $size-names {
    &--#{$size} {
      .c-icon-button__icon {
        width: map_get($icon-sizes, $size);
        height: map_get($icon-sizes, $size);
      }
    }
  }

  @each $color in $color-names {
    $current-color: map_get($colors, $color);

    &--#{$color} {
      color: $current-color;
      .c-icon-button__icon {
        fill: $current-color;
      }

      &:not(:disabled) {
        &:hover {
          color: lighten($current-color, $color-tint);
          .c-icon-button__icon {
            fill: lighten($current-color, $color-tint);
          }
        }
        &:focus {
          color: $border-color-focus;
          .c-icon-button__icon {
            fill: $border-color-focus;
          }
        }
      }
    }
  }
}
