@import "~blaze/scss/objects.panels";
@import "~blaze/scss/objects.drawers";
@import "~blaze/scss/objects.grid";
@import "~blaze/scss/objects.grid.responsive";
@import "~blaze/scss/objects.images";

// Grid
.o-grid {
  @for $i from 1 through length($size-names) {
    &--gutter-#{nth($size-names, $i)}{
      > .o-grid__cell {
        padding-left: nth($sizes, $i);
        padding-right: nth($sizes, $i);
        &--no-gutter {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &__cell {
    box-sizing: border-box;
  }
}

// Drawer
.o-drawer-container {
  position: relative;
  overflow: hidden;
}

// Icon
.o-svg-icon {
  display: inline;
  height: 100%;

  @each $size in $size-names {
    &--#{$size} {
      width: #{map_get($icon-sizes, $size)};
      height: #{map_get($icon-sizes, $size)};
    }
  }

  @each $color-name in $color-names {
    &--#{$color-name} {
      fill: map-get($colors , $color-name);
    }
  }
}

// Deck
.o-deck {
  display: flex;

  &--orientation-vertical {
    flex-direction: column;
    height: 100%;
  }

  &__item {
    &--stretch {
      flex: 1;
    }
  }

  @for $i from 1 through length($size-names) {
    &--spacing-#{nth($size-names, $i)}.o-deck--orientation-horizontal {
      > .o-deck__item {
        padding-right: nth($sizes, $i);
      }
    }
    &--spacing-#{nth($size-names, $i)}.o-deck--orientation-vertical {
      > .o-deck__item {
        padding-bottom: nth($sizes, $i);
      }
    }
  }

  &--orientation-horizontal {
    > .o-deck__item:last-child {
      padding-right: 0;
    }
  }

  &--orientation-vertical {
    > .o-deck__item:last-child {
      padding-bottom: 0;
    }
  }
}
