@import "../../theme/variables";
@import "../../theme/mixins";

$map-border-color: material-color('grey', '900');
$map-border: solid 1px $map-border-color;

.c-map {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: $map-border;
}
