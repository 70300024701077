
.c-error-box {
  max-width: 100%;
  max-height: 100%;
  &__message {
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }

}
