@import "~blaze/scss/mixins/_settings.global";

@import "~animatewithsass/_properties";
$countDefault: 0.5s;
$durationDefault: 0.5s;

@import "mixins";

// Sizes
$size-names: tiny xsmall small medium large xlarge super;
$sizes: $spacing-tiny $spacing-xsmall $spacing-small $spacing-medium $spacing-large $spacing-xlarge $spacing-super;
$icon-sizes: (
  tiny: 4px,
  xsmall: 8px,
  small: 16px,
  medium: 32px,
  large: 64px,
  xlarge: 128px,
  super: 256px,
);

// Colors
$text-color: $color-white;
$color-brand: material-color('deep-orange', '800');
$color-bg-brand: material-color('blue-grey', '900');
$color-names: default brand info warning success error;

// Typography
$text-font-family: 'Orbitron', $text-font-family;
$text-font-family-serif: 'Orbitron', $text-font-family-serif;

// Links
$link-hover-text-decoration: none;

// Inputs
$field-background-color: transparent;

// Buttons
$button-brand-background-color: $color-brand;
$button-ghost-brand-color: $color-brand;

// Tables
$table-heading-background-color: transparent;
$table-heading-color: $color-info;
$table-row-clickable-color: $color-default;
$table-row-striped-background-color: transparentize($color-white, 0.9);
$table-row-striped-color: $color-white;

// Cards
$card-background-color: transparentize($color-bg-brand, 0.7);
$card-border-radius: 0;
$card-item-border-bottom: 0;

// Drawers
$drawer-background-color: $card-background-color;
$drawer-color: $color-white;

// Progress Bar
$progress-striped-dash-size: 1em;
