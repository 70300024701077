@import "./../../theme/mixins";
@import "./../../theme/variables";

$icon-margin: 15%;

.c-planet {
  @include window-box--xsmall;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  line-height: 1;

  &__name {
    text-shadow: 1px 1px $color-black;
    z-index: 2;
    position: relative;
    @include screen--large-and-up {
      font-size: $text-font-size-xlarge;
    }
    font-size: $text-font-size-large;
  }

  &__port {
    @include window-box--xsmall;
    background-color: transparentize($badge-brand-background-color, 0.5);
    border-radius: 0 0 0 5px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    font-size: $text-font-size-xsmall;
  }

  &__icon {
    position: absolute;
    z-index: 0;
    top: $icon-margin;
    left: $icon-margin;
    width: 100% - $icon-margin * 2;
    height: 100% - $icon-margin * 2;
    box-sizing: border-box;
    filter: drop-shadow(2px 2px 5px $color-beige);

  }

  &__footer {
    @include window-box--xsmall;
    background-color: transparentize($color-black, 0.5);
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &--mode-lobby {
    .c-planet__footer {
      @include screen--large-and-up {
        font-size: $text-font-size-small;
      }
      font-size: $text-font-size-xsmall;
    }
  }
  &--mode-game {
    .c-planet__footer {
      font-size: $text-font-size-medium;
      text-align: right;
    }
  }
}
