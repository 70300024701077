@import "../../theme/_variables";

.c-player-icon {
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: $spacing-xsmall;

  &__status {
    position: absolute;
    right: 3px;
    top: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color-error;

    &--online {
      background-color: $color-success;
    }
  }
}
