@import "./../../theme/mixins";

.c-fleet {
  @include window-box--xsmall;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__icon {
    width: 100%;
    height: 60%;
  }
  &__text {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
