.c-planet-details {
  &__icon-container {
    position: relative;
    flex-grow: 1;
    max-width: 120px;
    .c-heading {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    img {
      opacity: 0.5;
    }
  }
}
