@import "~blaze/scss/utilities.alignment";
@import "~blaze/scss/utilities.boxing";
@import "~blaze/scss/utilities.sizes";
@import "~blaze/scss/utilities.elevation";
@import "~blaze/scss/blaze.colors.scss";

@import "~animatewithsass/_sliding-entrances/sliding-entrances";
@import "~animatewithsass/_sliding-exits/sliding-exits";

// Margins and paddings
$sides: left right top bottom;

@each $side in $sides {
  @for $i from 1 through length($size-names) {
    .u-m#{str-slice($side, 0, 1)}-#{nth($size-names, $i)} {
      margin-#{$side}: #{nth($sizes, $i)};
    }

    .u-m#{str-slice($side, 0, 1)}-zero {
      margin-#{$side}: 0;
    }

    .u-p#{str-slice($side, 0, 1)}-#{nth($size-names, $i)} {
      padding-#{$side}: #{nth($sizes, $i)};
    }

    .u-p#{str-slice($side, 0, 1)}-zero {
      padding-#{$side}: 0;
    }
  }
}

// Sizing
.u-full-height {
  box-sizing: border-box;
  height: 100%;
}

// Animations
.u-animation-slide-right {
  &--enter {
    @include slideInRight();
  }
  &--leave {
    @include slideOutRight();
  }
}
